import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../api";
import { EMPLOYEE_URI, APPLY_URI } from "../../../constants/api";
import { EMPLOYEE_TYPE_ENUM, EmployeeStatus } from "../../../constants/status";

export function useUpdateEmployee() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ data, employeeId = null, applyId }) => {
      const { fleet, courierDni, peonetaDni, ...employeeData } = data;

      const { data: updatedEmployee } = await axiosInstance.put(
        `/${employeeId}`,
        employeeData,
        {
          baseURL: EMPLOYEE_URI,
        }
      );

      const dni =
        updatedEmployee.type === EMPLOYEE_TYPE_ENUM.COURIER
          ? "courierDni"
          : "peonetaDni";

      await axiosInstance.put(
        `/${applyId}/vacancy`,
        {
          id: updatedEmployee.id,
          status: data?.status ?? EmployeeStatus.DRAFT,
          type: "employee",
          employeeType: updatedEmployee.type,
          name: updatedEmployee.name,
          lastname1: updatedEmployee.lastname1,
          lastname2: updatedEmployee.lastname2,
          phone: updatedEmployee.phone,
          email: updatedEmployee.email,
          plate: fleet.plate,
          documentTemplateId: employeeData.documentTemplateId,
          peonetaDni,
          courierDni,
          [dni]: updatedEmployee.numberRut,
        },
        { baseURL: APPLY_URI }
      );
      return updatedEmployee;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries("getFleetsByProviderAndOffer");
        queryClient.refetchQueries("listEmployeeByApply");
        queryClient.invalidateQueries("requestsById");
        queryClient.invalidateQueries("applyById");
      },
    }
  );
}

export function useUpdateEmployeeProvider() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ data, employeeId = null }) => {

      const response = await axiosInstance.put(
        `/${employeeId}`,
        data,
        {
          baseURL: EMPLOYEE_URI,
        }
      );
      return response;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries("employeesByProvider");
        queryClient.refetchQueries("getEmployee");
      },
    }
  );
}
