import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { OFFER_URI } from '../../../constants/api'

export function useCloseOffer() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation(
    (id) => axiosInstance.put(`/${id}`, {
      status: "Closed",
    }, {
      baseURL: OFFER_URI
    }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listOffers')
      },
    }
  )
}
