import { useState, useCallback, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Alert, Card, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { SelectorInput } from "./components/SelectorInput";
import {
  requestStatusOptions,
  requestTypes,
  requestTypesOptions,
  requestTypesValues,
} from "../../constants/requests";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useListProviderRequests } from "../../services/hooks/Request";
import {
  ENTITY_TYPES,
  PROVIDER_ASSETS_TYPE,
} from "../../../constants/entityTypes";
import { getKeyByValue } from "../../helper/object";
import { translatedStatus } from "../../../constants/status";
import { RequestTable } from "./components/RequestTable";
import { replacementTypes } from "./helper/entity";
import { useURLfilters } from "../../hooks/filters.hook";

import { useListBasesCodes } from "../../services/hooks/Bases/useListBasesCodes";
import { objectToTypeOptionList } from "../../helper/converters";

export const entityfilters = {
  courier: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.COURIER,
    page: 1,
  },
  peoneta: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.PEONETA,
    page: 1,
  },
  fleet: {
    entityType: ENTITY_TYPES.FLEET,
    subType: undefined,
    page: 1,
  },
  walker: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.WALKER,
    page: 1,
  },
};

export default function RequestsBackoffice() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState();
  const [status, setStatus] = useState();
  const [type, setType] = useState();
  const defaultFilters = {
    page: 1,
    limit: 10,
    ...entityfilters[currentTab || "courier"],
  };
  const {
    filters,
    setFilters,
    getURLValue,
    cleanFilters,
    getArrayFromURLValue,
  } = useURLfilters(defaultFilters, {
    excludeParams: ["tab"],
    persistParamOnClear: ["tab"],
  });

  const [alertError, setAlertError] = useState({
    open: false,
    title: "",
    messages: [],
  });
  const { data: listGeographiesCodes, isLoading: basesLoading } =
    useListBasesCodes();
  const [baseCode, setBaseCode] = useState(getURLValue("base", undefined));

  const onBaseChange = (event) => {
    const baseValue = event.target.value;
    setBaseCode(baseValue);
    const base = baseValue && baseValue.length === 0 ? undefined : baseValue;
    setFilters({ base, page: 1 });
  };

  const { data: { data, metadata } = {}, isLoading } =
    useListProviderRequests(filters);
  const onCloseAlert = () => setAlertError({ ...alertError, open: false });

  const onInfo = (request) => {
    const isReplacement = replacementTypes.includes(request.requestType);
    navigate(
      `/requests/${
        isReplacement
          ? "replacement"
          : request.requestType === requestTypesValues.REPLACE_DOCUMENT
          ? "replacementdocument"
          : request.requestType
      }/${request.id}`
    );
  };

  const onChangeTab = (tab) => {
    setCurrentTab(tab);
    setFilters(entityfilters[tab]);
  };

  useEffect(() => {
    onChangeTab(getURLValue("tab") ?? PROVIDER_ASSETS_TYPE.COURIER);
    setStatus();
    setType();
  }, [getURLValue("tab")]);

  const HeaderTabs = () => (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Tabs
        value={currentTab ?? getURLValue("tab") ?? PROVIDER_ASSETS_TYPE.COURIER}
        onChange={(e, searchInput) => {
          cleanFilters();
          onChangeTab(searchInput);
          navigate({
            pathname: "",
            search: `${createSearchParams({
              tab: searchInput,
            })}`,
          });
        }}
      >
        <Tab label="Vehículos" value={ENTITY_TYPES.FLEET} />
        <Tab label="Conductores" value={PROVIDER_ASSETS_TYPE.COURIER} />
        <Tab label="Peonetas" value={PROVIDER_ASSETS_TYPE.PEONETA} />
        <Tab label="Caminantes" value={PROVIDER_ASSETS_TYPE.WALKER} />
      </Tabs>
    </Stack>
  );

  const StatusSelector = useCallback(
    ({ onChange, value }) => (
      <SelectorInput
        onChange={onChange}
        value={value}
        label="Estado de solicitud"
        options={requestStatusOptions}
      />
    ),
    [status]
  );
  const TypeSelector = useCallback(
    ({ onChange, value }) => (
      <SelectorInput
        onChange={onChange}
        value={value}
        label="Tipo de solicitud"
        options={requestTypesOptions}
      />
    ),
    [type]
  );
  const BaseCodeSelector = useCallback(
    ({ onChange, value }) => {
      if (!listGeographiesCodes) return null;
      const bases = getArrayFromURLValue("base", undefined);
      return (
        <SelectorInput
          label="Seleccionar Base"
          value={value ?? bases}
          onChange={onChange}
          options={objectToTypeOptionList(listGeographiesCodes)}
        />
      );
    },
    [listGeographiesCodes, baseCode]
  );

  const onTypeChange = (e) => {
    setType(e.target.value);
    setFilters({ type: getKeyByValue(requestTypes, e.target.value), page: 1 });
  };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
    setFilters({
      status: getKeyByValue(translatedStatus, e.target.value),
      page: 1,
    });
  };

  const onCleanFilter = () => {
    setBaseCode(undefined);
    cleanFilters();
    setStatus();
    setType();
  };

  return (
    <Stack spacing={2}>
      <Grid
        sx={{
          justifyContent: "space-between",
        }}
        container
      >
        <Typography variant="h4" align="left">
          Solicitudes
        </Typography>
        <Grid>
          <BaseCodeSelector onChange={onBaseChange} value={baseCode} />
          <TypeSelector onChange={onTypeChange} value={type} />
          <StatusSelector onChange={onStatusChange} value={status} />
        </Grid>
      </Grid>
      <Grid
        sx={{
          justifyContent: "center",
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "0 8px 16px 0 #BDC9D7",
            }}
          >
            <HeaderTabs />
          </Card>
        </Grid>
      </Grid>
      {(isLoading || basesLoading) && <LoadingSpinner open />}
      <RequestTable
        data={data}
        entityType={currentTab ?? getURLValue("tab") ?? "courier"}
        metadata={metadata}
        onInfo={onInfo}
        getValue={getURLValue}
        clearFilters={onCleanFilter}
        setFilters={setFilters}
      />
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={onCloseAlert}
        handleClose={onCloseAlert}
        title={alertError.title}
        messages={alertError.messages}
      />
    </Stack>
  );
}
