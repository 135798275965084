import { useMutation, useQueryClient } from "react-query";
import { APPLY_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";

export const approvationMassive = async (data) => {
  const { axiosInstance } = useApi();
  const payload = {
    requests: data,
  };

  await axiosInstance.put('/massive/security', payload, {
      baseURL: APPLY_URI,
    }
  );

  return;
};

export function useApprovationMassive() {
  const queryClient = useQueryClient();
  return useMutation(approvationMassive, {
    onSuccess: () => {
      queryClient.refetchQueries("reportAllEmployee");
    },
    onError: () => {
      queryClient.refetchQueries("reportAllEmployee");
    },
  });
}