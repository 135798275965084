import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

export const SelectorInput = ({
  onChange,
  value = [],
  label = "Seleccione",
  options,
}) => {
  return (
    <FormControl size="small" sx={{ ml: 1 }}>
      <InputLabel id="active-label">{label}</InputLabel>
      <Stack sx={{ width: 300 }}>
        <Select
          multiple
          labelId="active-label"
          size="small"
          label={label}
          sx={{ borderRadius: "15px", backgroundColor: "white" }}
          value={Array.isArray(value) ? value : [value]}
          onChange={onChange}
          renderValue={(selected) => selected.join(", ")}
        >
          {options.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              <Checkbox checked={value.indexOf(item.name) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </FormControl>
  );
};
