/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";

import { PaperWhite } from "../../../components/Papers";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../../v2/components/Table";
import { Dialogs } from "../../../components/Dialogs";
import { AlertSnackBar } from "../../../components/Alerts";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

import { useDeleteBases, useListBases } from "../../../services/hooks/Bases";
import { DownloadExportData } from "../../../components/Excel/Excel";
import styles from "../../../v2/components/TabsAddon/TabsAddon.module.css";

const headCells = [
  {
    id: "name",
    label: "Nombre",
    exportExcel: true,
  },
  {
    id: "code",
    label: "Sigla",
    exportExcel: true,
  },
  {
    id: "localityName",
    label: "Ciudad",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
    exportExcel: false,
  },
];

const initialAlertError = {
  open: false,
  message: "",
};

export default function Bases() {
  const navigate = useNavigate();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState({});
  const [alertError, setAlertError] = useState(initialAlertError);
  const [formatToExcel, setFormatToExcel] = useState({});
  const [filters, setFilters] = useState({});

  const { data: bases, isLoading, error } = useListBases();
  const { mutate } = useDeleteBases();

  useEffect(() => {
    if (Array.isArray(bases) && bases.length) {
      setFormatToExcel(bases);
    }
  }, [bases]);

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertError(initialAlertError);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = async () => {
    setDeleteIsLoading(true);
    mutate(deleteId, {
      onSuccess: () => {
        setOpenDialogDelete(false);
        setDeleteIsLoading(false);
      },
      onError: () => {
        setOpenDialogDelete(false);
        setAlertError({
          open: true,
          message: "Oh no! se ha producido un error al deletar una base",
        });
      },
    });
  };

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value !== undefined) {
      setFilters((old) => ({ ...old, search: value }));
    }
  };

  const onChangeFilters = (newFilter = {}) => {
    setFilters({ ...filters, ...newFilter });
  };

  const { TableContainer } = Table(
    headCells,
    bases?.metadata,
    onChangeFilters
  );

  if (error) {
    return (
      <PaperWhite
        sx={{
          color: "primary.main",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography variant="h4">No se encontraron datos!</Typography>
      </PaperWhite>
    );
  }

  if (isLoading) {
    return <LoadingSpinner open />;
  }

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.tabAddon}
    >
      <Box sx={{ display: "flex" }}>
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="buscar"
          onSearch={handleSearch}
        />

        <DownloadExportData
          data={formatToExcel}
          columns={headCells}
          nameFile="Bases"
        />
      </Box>

      <Button
        variant="contained"
        startIcon={<AddCircleOutlined color="inherit" />}
        onClick={() =>
          navigate("/Bases/create", {
            state: {
              fromButton: true,
            },
          })
        }
      >
        Crear Nuevo
      </Button>
    </Stack>
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Bases
      </Typography>
      {!error && !isLoading && (
          <TableContainer addons={[TabsAddon]}>
            <TableBody>
              {bases?.bases.map(
                ({ id, name, code, localityName, localityId }) => {
                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <Typography>{name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{code}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{localityName}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        <Stack direction="row" spacing={2}>
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/Bases/update/${id}?localityId=${localityId}`,
                                {
                                  state: {
                                    fromButton: true,
                                  },
                                }
                              )
                            }
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpenDialogDelete(true);
                              setDeleteId({ id, localityId });
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </TableContainer>
      )}

      <Dialogs
        open={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirmation={handleDelete}
        isLoading={deleteIsLoading}
        title="¿Estás seguro de que quieres eliminar una base?"
      />

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  );
}
