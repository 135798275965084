import { memo, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Divider, Stack, Button, Grid, Box, FormControlLabel, Switch, Typography, Checkbox } from "@mui/material"
import { EmployeeSection } from "./ProviderSection"
import { CompanySection } from "./CompanySection"
import { DocumentsSection } from "../Documents/DocumentSection"
import { ArrowBackOutlined } from "@mui/icons-material"
import { AddressSectionProvider } from "./AddressSectionProvider"
import { useGetTemplateByName } from "../../../services/hooks/Docs/Templates/useGetTemplates"
import { LoadingSpinner } from "../../LoadingSpinner"
import { templateNames } from "../../../constants/templateNames"
import { ProviderBoletaSection } from "./ProviderBoletaSection";
import { PaperWhite } from "../../Papers";
import { BankSection } from "./BankSection";
import { BpCheckedIcon, BpIcon } from "../../../v2/components/Button/CheckedButton";
import { useContextMessageContext } from "../../../v2/context/contextMessage/contextMessage.hook";

export const FormCreate = memo(({formValues, onSubmit, goBack, setTemplate, isEditing}) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState,
  } = useForm({defaultValues: formValues, mode: "onChange"});
  const [currentValues, setCurrentValues] = useState(formValues);
  const contractType = useWatch({ control, name: "contractType" });
  const handleChange = () => {
    setCurrentValues((prev) => ({...prev, ...getValues()}));
  }
  const { data, isLoading } = useGetTemplateByName(contractType ? templateNames.providerBoleta : templateNames.provider)
  const {
    termsAndConditions: termsAndConditionsMessage,
    privacyPolicies,
    isLoading: isLoadingContextMessages
  } = useContextMessageContext();
  
  useEffect(() => {
    if (data) {
      setTemplate(data);
    }
  }, [data]);

  useEffect(() => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, currentValues[key]);
    })
  },[]);

  const completeAddress = ({ street, number, city, commune, zipCode, region }, results ) => {
    results[0]?.address_components.forEach((item) => {
      if (street && item.types.includes('route')) {
        setValue(street, item.long_name)
      }
      if (number && item.types.includes('street_number')) {
        setValue(number, item.long_name)
      }
      if (city && item.types.includes('administrative_area_level_2')) {
        setValue(city, item.long_name)
      }
      if (commune && (item.types.includes('sublocality') || item.types.includes('locality'))) {
        setValue(commune, item.long_name)
      }
      if (zipCode && item.types.includes('postal_code')) {
        setValue(zipCode, item.long_name)
      }
      if (region && item.types.includes('administrative_area_level_1')) {
        setValue(region, item.long_name)
      }
    })
  }

  const completeAddressProvider = (values) => {
    const { results, coordinates } = values
    completeAddress({
      street: 'streetProvider',
      number: 'numberProvider',
      city: 'cityProvider',
      commune: 'communeProvider',
      zipCode: 'zipCodeProvider',
      region: 'regionProvider',
    }, results, coordinates)
  }

  if (isLoading ) return <LoadingSpinner open />

  return (
    <Stack
      spacing={2}
      component='form'
      onChange={handleChange}
      onSubmit={handleSubmit(onSubmit)}
    >
      <PaperWhite>
        {!isEditing ?
          <>
            <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
              {/* <Box
                sx={{
                  bgcolor: "#fffbea",
                  borderRadius: "10px",
                  border: "solid 2px #ffe58f",
                  borderColor: "#ffe58f",
                  mb: 4,
                }}
              >
                <Stack sx={{ mx: 2, mt: 2, mb: 2}}>
                  <Stack sx={{ flexDirection: "row" }}>
                    <WarningIcon
                      sx={{ alignSelf: "center", mr: 2, width: "32px", height: "32px" }}
                    />
                    <Typography
                      fontFamily="Lato"
                      variant="h6"
                      fontWeight={1400}
                      color="#d48806"
                    >
                      Seleccionar su tipo de proveedor
                    </Typography>
                    </Stack>
                    <Typography
                    >
                      Use el interruptor a continuación para seleccionar el tipo de proveedor:
                    </Typography>
                    <Typography
                    >
                      <b>
                        Factura (interruptor apagado - gris)
                      </b>
                    </Typography>
                    <Typography
                    >
                      <b>
                      Boleta de Honorarios (interruptor encendido - amarillo)
                      </b>
                    </Typography>
                </Stack>
              </Box> */}
              <Typography variant='h4' sx={{ backgroundColor: 'yellow', color: 'red', padding:2 }}>
                Seleccionar si usted será un proveedor de factura (botón gris) o boleta de honorarios (botón naranjo).
              </Typography>
              <Controller
                control={control}
                name="contractType"
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        defaultChecked={value}
                        onChange={onChange}
                      />
                    }
                    label={contractType ? "Boleta" : "Factura"}
                  />
                )}
                />
            </Box>
            {!contractType ? (
              <>
                <EmployeeSection control={control} getValue={getValues}/>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <AddressSectionProvider control={control} completeDirection={completeAddressProvider} title={'Dirección del proveedor'}/>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <CompanySection control={control} getValue={getValues} isEditing={isEditing}/>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <DocumentsSection control={control} documentStructure={data.documentTypes} />
              </>
            ): !isLoadingContextMessages ? (
              <>
                <ProviderBoletaSection control={control} getValue={getValues} isEditing={isEditing} />
                <Divider sx={{ pt: 1, pb: 2 }} />
                <AddressSectionProvider control={control} completeDirection={completeAddressProvider} title={'Dirección del proveedor'}/>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <BankSection control={control} getValue={getValues} isEditing={isEditing}/>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <DocumentsSection control={control} documentStructure={data.documentTypes} />
                <Box gridColumn={{ xs: "span 12", lg: "span 2" }}>
                  <Controller
                    control={control}
                    name="termsAndConditions"
                    rules={{
                      required: "(*) Campo obligatorio",
                    }}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        key="termsAndConditions"
                        control={
                          <Stack direction='row' spacing={1} alignItems='center'>
                            <Checkbox
                              checked={value}
                              onChange={onChange}
                              color="secondary"
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                              required
                            />
                            <Typography
                              variant="body1"
                              fontFamily="Lato"
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="18.9px"
                              sx={{
                                color: "rgba(0, 0, 0, 0.87)",
                                "& span": { color: "orange" },
                                mt: 0,
                              }}
                            >
                              {"He leído y acepto los "}
                              <a
                                target="_blank"
                                href={termsAndConditionsMessage?.referenceUrl}
                                rel="noreferrer"
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                {termsAndConditionsMessage?.label}
                              </a>
                              {" del servicio y autorizo el uso de mis datos de acuerdo a la "}
                              <a
                                target="_blank"
                                href={privacyPolicies?.referenceUrl}
                                rel="noreferrer"
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                {privacyPolicies?.label}
                              </a>
                            </Typography>
                          </Stack>
                        }
                      />
                    )}
                  />
                </Box>
              </>
            ) : <LoadingSpinner open />}
          </>
          :
          <CompanySection control={control} getValue={getValues} isEditing={isEditing}/>
        }
        <Grid container sx={{ pt: 2}} >
          <Grid item xs={5}>
            { goBack &&
              <Button
                variant='goBack'
                disableRipple
                startIcon={<ArrowBackOutlined />}
                onClick={goBack}
                sx={{ height: 40, fontSize: 12, alignSelf: 'self-start' }}
              >
                { "Volver atrás" }
              </Button>
            }
          </Grid>
          <Grid item xs={7}>
            <Grid container justifyContent="flex-end">
              <Button
                disabled={!formState.isValid}
                color='secondary'
                type='submit'
                sx={{ minWidth: '155px', height: 40 }}
              >
                { "Guardar" }
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </PaperWhite>
    </Stack>
  )
})