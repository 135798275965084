import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Switch,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from "@mui/material";
import { useListEmployee } from "../../../services/hooks/Employee/useListEmployee";
import { useDeleteHardEmployee } from "../../../services/hooks/Employee/useDeleteHardEmployee";
import { Table } from "../../../components/Table";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { ActiveStatus, EmployeeStatus, FILTER_EMPLOYEE_LIST, translatedStatus } from "../../../../constants/status";
import ListDocumentsModal from "../../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../../constants/vacancyType";
import { headCellsEmployeeBackOffice } from "../../../helper/headcell";
import { TabAddonsList } from "../../../components/TabsAddon";
import { useURLfilters } from "../../../hooks/filters.hook";
import { TableButton } from "../../../components/Button/TableButton";
import { ReactComponent as EditButtonIcon } from "../../../assets/images/editbutton.svg";
import { ReactComponent as InfoButton } from "../../../assets/images/infobutton.svg";
import { ReactComponent as RemoveButton } from "../../../assets/images/trash.svg";
import { useReportEmployee } from "../../../services/hooks/Employee/useReportEmployees";
import { urlDownload } from "../../../helper/files";
import { AlertSnackBar } from "../../../../components/Alerts";
import { Dialogs } from "../../../../components/Dialogs";
import { useUpdateActiveStatus } from "../../../services/hooks/Employee/useUpdateActiveStatus";
import { Alert } from "../../../../components/Alert";
import wowIcon from "../../../../assets/images/wow.png";
import { useListBasesCodes } from "../../../services/hooks/Bases/useListBasesCodes";
import { SelectorInput } from "../../Requests/components/SelectorInput";
import { objectToTypeOptionList } from "../../../helper/converters";
import { formatDateApprovalFlow } from "../../../../utils/formatValues";
import { ProtectedComponent } from "../../../../components/ProtectedComponent";
import { hasFuncionality } from "../../../../utils/permissions";
import { ACTION_EMPLOYEE, getFuncionalityEmployee } from "../../../helper/employeeFuncionality";
import { getKeyByValue } from "../../../helper/object";
import { ChipStatus } from "../../../components/Chip";
import { ENTITY_TYPES_MAPPING_PLURAL } from "../../../../constants/entityTypes";

export default function ListEmployee({ type }) {
  const navigate = useNavigate();
  const defaultStatusFilter = `${EmployeeStatus.APPROVED},${EmployeeStatus.BLOCKEDBYDOCUMENTS}`;
  const defaultFilters = {
    type: type,
    status: defaultStatusFilter,
    page: 1,
    limit: 10,
  };
  const [alert, setAlert] = useState({
    title: "",
    open: false,
    messages: [],
    icon: "",
    buttonClose: true,
  });

  const [item, setItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [employeeSelected, setEmployeeSelected] = useState();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogActiveStatus, setOpenDialogActiveStatus] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [error, setError] = useState({ isOpen: false });
  const { mutate: mutateReportEmployee, isLoading: isLoadingReport } =
    useReportEmployee();
  const { data: listGeographiesCodes, isLoading: basesLoading } =
    useListBasesCodes();

  const {
    filters,
    setFilters,
    cleanFilters,
    getURLValue,
    getArrayFromURLValue,
  } = useURLfilters(defaultFilters);
  const { data, isLoading } = useListEmployee({...filters, status: filters?.status ? filters.status : defaultStatusFilter});
  const [baseCode, setBaseCode] = useState(getURLValue("base", undefined));
  const [selectedStatus, setSelectedStatus] = useState(getURLValue("status", undefined));

  const onChangeFilters = (newFilter = {}) => {
    setFilters({ ...filters, ...newFilter });
  };
  const onBaseChange = (event) => {
    const baseValue = event.target.value;
    setBaseCode(baseValue);
    const base = baseValue && baseValue.length === 0 ? undefined : baseValue;
    setFilters({ base, page: 1 });
  };
  const onStatusChange = (event) => {
    const statusValue = event.target.value;
    setSelectedStatus(statusValue);
    setFilters({ status: getKeyByValue(EmployeeStatus, statusValue), page: 1 });
  };

  const StatusSelector = useCallback(
    ({ onChange, value }) => {
      const status = getArrayFromURLValue("status", []).map((item) => translatedStatus[item]);
      return (
        <SelectorInput
          label="Seleccionar Estado"
          value={value ?? status}
          onChange={onChange}
          options={objectToTypeOptionList(FILTER_EMPLOYEE_LIST)}
        />
      );
    },
    [data, translatedStatus]
  );

  const cleanAllFilters = () => {
    cleanFilters();
    setBaseCode(undefined);
    setSelectedStatus(['Aprobado', 'Bloqueado por documentación']);
  };

  const canActivate = !hasFuncionality(getFuncionalityEmployee(type, ACTION_EMPLOYEE.ACTIVATE));
  const { TableContainer, setTableLoading, setPage } = Table(
    headCellsEmployeeBackOffice,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  const { mutate: mutateDeleteEmployee, isLoading: isLoadingDeleteEmployee } =
    useDeleteHardEmployee();
  const {
    mutate: mutateUpdateActiveStatus,
    isLoading: isLoadingUpdateActiveStatus,
  } = useUpdateActiveStatus();

  const handleDelete = () => {
    mutateDeleteEmployee(
      { idToDelete },
      {
        onSuccess: () => {
          setOpenDialogDelete(false);
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: ["El empleado ha sido eliminado con éxito"],
          });
        },
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message:
              "Hubo un error al eliminar el empleado. Inténtelo nuevamente.",
            handleClose: () => setError({ isOpen: false }),
          });
        },
      }
    );
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  useEffect(() => {
    setTableLoading(isLoading);
    if (data) {
      setDownloadData(
        data.data.map((item) => {
          return {
            numberRut: item.numberRut,
            entryDate: item?.entryDate ?? "-",
            name: `${item.name} ${item?.lastname1 ?? ""} ${
              item?.lastname2 ?? ""
            }`.trim(),
            email: item.email,
            numberRutProvider: item.provider?.numberRut ?? "",
            nameProvider: item.provider?.name ?? "",
            active: item?.active?.isActive
              ? ActiveStatus.ACTIVE
              : ActiveStatus.INACTIVE,
            blocked: item.blocked?.isBlocked ? "SI" : "NO",
          };
        })
      );
    }
  }, [data, isLoading]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeBlocked = (event) => {
    onChangeFilters({ isBlocked: event.target.value, page: 1 });
  };

  const onChangeActive = (event) => {
    onChangeFilters({ isActive: event.target.value, page: 1 });
  };

  const handleCloseDialogActive = () => {
    setOpenDialogActiveStatus(false);
  };

  const handleActiveStatus = () => {
    const action = employeeSelected?.active?.isActive ? "inactive" : "active";
    const id = employeeSelected?.id;
    mutateUpdateActiveStatus(
      { id, action },
      {
        onSuccess: () => {
          setOpenDialogActiveStatus(false);
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: ["El empleado ha sido actualizado con éxito"],
          });
        },
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message:
              "Hubo un error al eliminar el empleado. Inténtelo nuevamente.",
            handleClose: () => setError({ isOpen: false }),
          });
        },
      }
    );
  };

  const onEditDocument = (item) => {
    navigate(`/employee/edit/${item.id}`);
  };

  const handleDownloadReport = () => {
    mutateReportEmployee({...filters, status: filters?.status ? filters.status : defaultStatusFilter}, {
      onSuccess: (filePath) => {
        urlDownload(filePath);
      },
      onError: () => {
        setError({
          isOpen: true,
          title: "Error",
          message:
            "Hubo un error al descargar el reporte de empleados. Inténtelo nuevamente.",
          handleClose: () => setError({ isOpen: false }),
        });
      },
    });
  };

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };
  const BaseCodeSelector = useCallback(
    ({ onChange, value }) => {
      if (!listGeographiesCodes) return null;
      const bases = getArrayFromURLValue("base", undefined);
      return (
        <SelectorInput
          label="Seleccionar Base"
          value={value ?? bases}
          onChange={onChange}
          options={objectToTypeOptionList(listGeographiesCodes)}
        />
      );
    },
    [listGeographiesCodes, baseCode]
  );

  const TabsAddons = () => (
    <TabAddonsList
      search={getURLValue("search", "")}
      clearFilters={cleanAllFilters}
      active={getURLValue("isActive", "")}
      blocked={getURLValue("isBlocked", false)}
      downloadData={downloadData}
      handleSearch={handleSearch}
      onChangeActive={onChangeActive}
      onChangeBlocked={onChangeBlocked}
      headCell={headCellsEmployeeBackOffice}
      nameFile={type}
      handleDownloadReport={handleDownloadReport}
    />
  );

  if (isLoading || isLoadingReport || basesLoading)
    return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h4">{ENTITY_TYPES_MAPPING_PLURAL[type]}</Typography>
        <Stack direction="row" spacing={2}>
          <Grid>
            <ProtectedComponent functionality={[getFuncionalityEmployee(type, ACTION_EMPLOYEE.FILTER)]}>
              <BaseCodeSelector onChange={onBaseChange} value={baseCode} />
            </ProtectedComponent>
          </Grid>
          <Grid>
            <ProtectedComponent functionality={[getFuncionalityEmployee(type, ACTION_EMPLOYEE.FILTER)]}>
              <StatusSelector onChange={onStatusChange} value={selectedStatus} />
            </ProtectedComponent>
          </Grid>
        </Stack>
      </Stack>
      <TableContainer addons={[TabsAddons]} withPagination>
        <TableBody>
          {data?.data.map((employee) => (
            <TableRow hover tabIndex={-1} key={employee.id}>
              <TableCell>
                <ChipStatus
                  label={translatedStatus[employee.status]}
                  setcolor={employee.status}
                />
              </TableCell>
              <TableCell>{employee.base?.code}</TableCell>
              <TableCell>
                {employee?.entryDate
                  ? formatDateApprovalFlow(employee?.entryDate)
                  : "-"}
              </TableCell>
              <TableCell>
                {`${employee.name} ${employee?.lastname1 ?? ""} ${
                  employee?.lastname2 ?? ""
                }`.trim()}
              </TableCell>
              <TableCell>{employee.numberRut}</TableCell>
              <TableCell sx={{ opacity: 1 }}>{employee.email}</TableCell>
              <TableCell>
                {`${employee.provider?.name ?? "-"}`.trim()}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee.provider
                  ? employee.provider?.numberRut
                  : "-"}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                <Switch
                  color="warning"
                  onClick={() => {
                    setOpenDialogActiveStatus(true);
                    setEmployeeSelected(employee);
                  }}
                  defaultChecked={employee?.active?.isActive}
                  disabled={canActivate}
                />
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {employee.blocked?.isBlocked ? "SI" : "NO"}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <ProtectedComponent functionality={[getFuncionalityEmployee(type, ACTION_EMPLOYEE.EDIT)]}>
                    <TableButton onClick={() => onEditDocument(employee)}>
                      <EditButtonIcon />
                    </TableButton>
                  </ProtectedComponent>
                  <TableButton
                    onClick={() => {
                      setItem(employee);
                      setShowModal(true);
                    }}
                  >
                    <InfoButton />
                  </TableButton>
                  <ProtectedComponent functionality={[getFuncionalityEmployee(type, ACTION_EMPLOYEE.DELETE)]}>
                    <TableButton
                      onClick={() => {
                        setIdToDelete(employee?.id);
                        setOpenDialogDelete(true);
                      }}
                    >
                      <RemoveButton />
                    </TableButton>
                  </ProtectedComponent>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.EMPLOYEE}
        item={item}
      />
      <Dialogs
        open={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirmation={handleDelete}
        isLoading={isLoadingDeleteEmployee}
        title="¿Estás seguro de que quieres eliminar este empleado?"
        content={"Esta acción no se puede deshacer"}
      />
      <Dialogs
        open={openDialogActiveStatus}
        handleClose={handleCloseDialogActive}
        handleConfirmation={handleActiveStatus}
        isLoading={isLoadingUpdateActiveStatus}
        title={`¿Estás seguro de que quieres ${
          employeeSelected?.active?.isActive ? "inactivar" : "activar"
        } este empleado?`}
        content={"Esta acción no se puede deshacer"}
      />
      <Alert
        open={alert.open}
        title={alert.title}
        messages={alert.messages}
        icon={alert.icon}
        buttonClose={alert.buttonClose}
        onClose={handleCloseAlert}
        handleConfirmation={handleCloseAlert}
      />
    </Stack>
  );
}
