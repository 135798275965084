import { Stack } from "@mui/material";
import { employeeType } from "../../../constants/employeeType";
import { MyEmployeesList } from "./components/MyEmployeesList";

export default function ProviderCouriersList() {
  return (
    <Stack spacing={2}>
      <MyEmployeesList myEmployeeType={employeeType.COURIER} />
    </Stack>
  );
}
