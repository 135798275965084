/* eslint-disable no-plusplus */
export const maskOnlyNumber = (value) => {
  const text = String(value)
  return Number(text.replace(/\D/g, '')) || 0
}

export const maskCurrency = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value)

export const maskCurrencyCLP = (value) =>
  new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  }).format(value)

export const maskRUT = (rut) => {
  const newRut = String(rut)
    .replace(/\./g, '')
    .replace(/-/g, '')
    .trim()
    .toLowerCase()
  const lastDigit = newRut.substr(-1, 1)
  const rutDigit = newRut.substr(0, newRut.length - 1)
  let format = ''
  let addPoint = false
  for (let i = rutDigit.length; i > 0; i--) {
    const e = rutDigit.charAt(i - 1)
    const preFormat = e.concat(format)
    const fLength = preFormat.replace(/\./g, '').length
    if (addPoint) {
      format = '.'.concat(format)
    }
    format = e.concat(format)
    addPoint = (fLength % 3 === 0 && fLength >= 3)
  }
  return (format.length > 0 && lastDigit.length > 0) ? format.concat('-').concat(lastDigit) : format.concat(lastDigit)
}

export const maskTel = (value) => 
  value
    .replace(/[^+0-9]/g, '')
    .replace(/(^\d)/, '+$1')
    .replace(/(^\+\d{2})(\d{1}|\d{1}$)?(\d{4})?(\d{1,4})$/, '$1 $2 $3 $4')
    .replace(/\s{2,}/g, ' ');

export const maskNumberAdress = (value) =>
  value.replace(/\D/g, '').replace(/(\d{5})\d+?$/, '$1')

export const maskPlate = (value) => value.trim().toUpperCase().replace(/[^a-zA-Z0-9 ]/gi, '');