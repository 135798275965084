export const headCellsEmployeeBackOffice = [
  {
    id: "status",
    label: "Estado",
    exportExcel: true,
  },
  {
    id: "base",
    label: "Base",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "entryDate",
    label: "Fecha de ingreso",
    exportExcel: true,
  },
  {
    id: "name",
    label: "Nombre",
    exportExcel: true,
  },
  {
    id: "numberRut",
    label: "RUT",
    exportExcel: true,
  },
  {
    id: "email",
    label: "Correo",
    exportExcel: true,
  },
  {
    id: "nameProvider",
    label: "Nombre Proveedor",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "numberRutProvider",
    label: "Rut Proveedor",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "active",
    label: "Activo/Inactivo",
    exportExcel: true,
  },
  {
    id: "blocked",
    label: "Bloqueado",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export const headCellsProviderBackOffice = [
  {
    id: "numberRut",
    label: "RUT Razón social",
    exportExcel: true,
  },
  {
    id: "entryDate",
    label: "Fecha de ingreso",
    exportExcel: true,
  },
  {
    id: "name",
    label: "Nombre razón social",
    exportExcel: true,
  },
  {
    id: "numberRutLegalRepresentative",
    label: "Rut Representante legal",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "nameLegalRepresentative",
    label: "Nombre Representante legal",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "email",
    label: "Correo electrónico",
    exportExcel: true,
  },
  {
    id: "phone",
    label: "Número de contacto",
    exportExcel: true,
  },
  {
    id: "address",
    label: "Dirección comercial",
    exportExcel: true,
  },
  {
    id: "commune",
    label: "Comuna",
    exportExcel: true,
  },
  {
    id: "active",
    label: "Activo/Inactivo",
    exportExcel: true,
  },
  {
    id: "blocked",
    label: "Bloqueado",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export const headCellsFleets = [
  {
    id: "status",
    label: "Estado",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "base",
    label: "Base",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "entryDate",
    label: "Fecha de ingreso",
    exportExcel: true,
  },
  {
    id: "plate",
    label: "Patente",
    exportExcel: true,
  },
  {
    id: "brand",
    label: "Marca",
    exportExcel: true,
  },
  {
    id: "model",
    label: "Modelo",
    exportExcel: true,
  },
  {
    id: "year",
    label: "Año",
    exportExcel: true,
  },
  {
    id: "nameProvider",
    label: "Nombre Proveedor",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "numberRutProvider",
    label: "Rut Proveedor",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "active",
    label: "Activo/Inactivo",
    exportExcel: true,
    disableSorting: true,
  },
  {
    id: "blocked",
    label: "Bloqueado",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export const headCellsProviderFleetList = [
  {
    id: "status",
    label: "Estado",
  },
  {
    id: "plate",
    label: "Patente",
  },
  {
    id: "brand",
    label: "Marca",
  },
  {
    id: "model",
    label: "Modelo",
  },
  {
    id: "type",
    label: "Tipo",
    disableSorting: true,
  },
  {
    id: "active",
    label: "Activo/Inactivo",
    disableSorting: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export const headCellsProviderEmployeeList = [
  {
    id: "status",
    label: "Estado",
    disableSorting: true,
  },
  {
    id: "name",
    label: "Nombre",
    disableSorting: true,
  },
  {
    id: "numberRut",
    label: "Rut",
    disableSorting: true,
  },
  {
    id: "active",
    label: "Activo/Inactivo",
    disableSorting: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];
