import React, { useCallback, useState } from "react";
import { MessageBox } from "../../../components/common/MessageBox";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useGetDocuments } from "../../../services/hooks/Documents";
import { Alert } from "../../../components/Alert";

import {
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { Table } from "../Table";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CommonStatus, EmployeeStatus } from "../../../constants/status";
import { formatDateApprovalFlow } from "../../../utils/formatValues";
import { ArrowBackOutlined } from "@mui/icons-material";
import { ChipStatus } from "../Chip";
import EntityDocumentsModalUpdateMany from "../Modal/EntityDocumentsModalUpdateMany";
import { useGetDocumentsByEntity } from "../../../services/hooks/Docs/S3/useGetDocumentsByEntity";
import {
  ENTITY_TYPES,
  PROVIDER_ASSETS_TYPE,
} from "../../../constants/entityTypes";
import warning from "../../../assets/images/warning.svg";
import { REQUEST_TYPE } from "../../helper/request";
import { useCreateReplaceDocument } from "../../services/hooks/Request/ReplaceDocument/useCreateReplaceDocument";
import { useReplaceManyDocument } from "../../../services/hooks/Docs/S3/useReplaceManyDocument";
import { useUpdateEntity } from "../../services/hooks/entities/updateEntityById";

const headCells = [
  {
    id: "title",
    label: "Documento",
    exportExcel: true,
  },
  {
    id: "file",
    label: "Archivo",
    exportExcel: true,
  },
  {
    id: "expiredDate",
    label: "Fecha vencimiento",
    exportExcel: true,
  },
  {
    id: "state",
    label: "Estado",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
    exportExcel: false,
  },
];

const initialAlert = {
  open: false,
  icon: "",
  title: "",
  messages: [],
  content: "",
};

const formValues = {};

export function EntityDocumentListUpdateMany({
  type,
  id,
  templateData,
  goBack,
  entity,
  setReplaceFinish,
}) {
  const [formData, setFormData] = useState(formValues);
  const [alert, setAlert] = useState(initialAlert);
  const [disabledButton, setDisabledButton] = useState(true);

  const { data: documentsFromBucket } = useGetDocumentsByEntity(id, type);

  const { data, isLoading, error } = useGetDocuments(type, id);
  const [showModal, setShowModal] = useState(false);
  const [documentUpload, setDocumentUpload] = useState();
  const { mutate: mutateUpdateDocument, isLoading: isLoadingDocuments } =
    useReplaceManyDocument();
  const { mutate: mutateReplaceDocument, isLoading: isLoadingReplaceDocument } =
    useCreateReplaceDocument();
  const { mutate: updateEntity } = useUpdateEntity(type);

  const { TableContainer } = Table(headCells, data);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmation = () => {
    //handleFinishAction();
    //onClose();
    setAlert({
      ...alert,
      open: false,
    });
  };

  const submitData = (data) => {
    setShowModal(false);
    setDisabledButton(false);
    setFormData(data);
  };

  const addRequestedToEntity = (requestId) => {
    updateEntity(
      {
        id,
        requested: {
          replaceDocument: requestId,
        },
      },
      {
        onSuccess: () => {
          setReplaceFinish(true);
        },
      }
    );
  };

  const submitUpdateDocuments = useCallback(() => {
    const relatedDocumentIds = [];
    const beforeUpload = {
      file: [],
      metaData: [],
    };

    Object.entries(formData).forEach(([, documentToUpload]) => {
      beforeUpload["ownerId"] = id;
      beforeUpload["ownerType"] = type;
      beforeUpload.metaData = [
        ...beforeUpload.metaData,
        ...documentToUpload.metaData.map((metadata) => ({
          ...metadata,
          expirationDate: documentToUpload.expirationDate,
          documentTypeId: documentToUpload.documentTypeId,
          status: documentToUpload.status,
        })),
      ];
      beforeUpload.file = [...beforeUpload.file, ...documentToUpload.file];
      if (!documentToUpload.file.some((docFile) => docFile === null)) {
        const currentMetadataGroupName =
          documentToUpload.metaData[0]?.groupName;

        const relatedDocument = documentsFromBucket.find(
          (relDoc) => relDoc.files[0].groupName === currentMetadataGroupName
        );
        relatedDocumentIds.push(relatedDocument?.id);
      }
    });

    beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);

    const createDocumentOnSuccess = updateDocumentRequest(beforeUpload);

    createReplaceDocumentRequest(relatedDocumentIds, createDocumentOnSuccess);
  }, [formData]);

  const updateDocumentRequest = (beforeUploadData) => (requestResponse) => {
    beforeUploadData.ownerId = requestResponse.data.id;
    beforeUploadData.ownerType = "requests";

    mutateUpdateDocument(
      {
        id: entity.id,
        documents: beforeUploadData,
      },
      {
        onSuccess: (response) => addRequestedToEntity(requestResponse.data.id),
        onError: () => {
          setAlert({
            ...alert,
            icon: warning,
            open: true,
            title: "Error",
            messages: [
              "Oh no! se ha producido un error al cargar los documentos",
            ],
          });
        },
      }
    );
  };

  const createReplaceDocumentRequest = (
    relatedDocumentIds,
    createDocuments
  ) => {
    const dataReplaceDocument = {
      type: REQUEST_TYPE.REPLACE_DOCUMENT,
      requested: {
        for: {
          type:
            entity.type === PROVIDER_ASSETS_TYPE.COURIER ||
            entity.type === PROVIDER_ASSETS_TYPE.PEONETA ||
            entity.type === PROVIDER_ASSETS_TYPE.WALKER
              ? ENTITY_TYPES.EMPLOYEE
              : ENTITY_TYPES.FLEET,
          subType: entity.type,
          plate: entity.plate || "",
          courierDni:
            entity.type === PROVIDER_ASSETS_TYPE.COURIER ||
            entity.type === PROVIDER_ASSETS_TYPE.WALKER
              ? entity.numberRut
              : entity.courierDni,
          peonetaDni:
            entity.type === PROVIDER_ASSETS_TYPE.PEONETA
              ? entity.numberRut
              : entity.peonetaDni,
          provider: entity.provider,
          base: entity.base,
        },
      },
      status: CommonStatus.PENDING,
      metadata: {
        entity: entity,
        setting: {
          documentIdToReplace: relatedDocumentIds,
        },
      },
    };

    return mutateReplaceDocument(dataReplaceDocument, {
      onSuccess: createDocuments,
    });
  };

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || isLoadingDocuments || isLoadingReplaceDocument)
    return <LoadingSpinner open />;
  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    ></Stack>
  );

  return (
    <Stack spacing={4}>
      {data.length ? (
        <>
          <TableContainer addons={[TabsAddon]}>
            <TableBody>
              {data.map((document) => (
                <TableRow hover tabIndex={-1} key={document.id}>
                  <TableCell>{document.files[0].groupName}</TableCell>
                  <TableCell>
                    {document.files.map((file) => (
                      <Stack key={file.name}>
                        <Button
                          variant="goBack"
                          disableRipple
                          startIcon={<VisibilityIcon />}
                          sx={{
                            height: 40,
                            fontSize: 14,
                            alignSelf: "self-start",
                          }}
                        >
                          <Link
                            href={file.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={file?.name}
                          >
                            {file?.labelName}
                          </Link>
                        </Button>
                      </Stack>
                    ))}
                  </TableCell>
                  <TableCell>
                    {document.expirationDate
                      ? formatDateApprovalFlow(
                          new Date(document.expirationDate)
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <ChipStatus
                      label={EmployeeStatus[document.status]}
                      setcolor={document.status}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setDocumentUpload(document);
                        setShowModal(true);
                      }}
                      disabled={
                        document.status === EmployeeStatus.PENDING ||
                        !!entity?.requested
                      }
                    >
                      <Stack
                        sx={{ height: 40, fontSize: 14, alignItems: "center" }}
                      >
                        <FileUploadIcon />
                        Cargar Archivo
                      </Stack>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <EntityDocumentsModalUpdateMany
            isOpen={showModal}
            onClose={handleCloseModal}
            templateData={templateData}
            formValues={formData}
            documentUpload={documentUpload}
            submitData={submitData}
          />
        </>
      ) : null}
      <Grid
        display="flex"
        item
        xs={5}
        sx={{ mt: 5 }}
        width="100%"
        justifyContent="space-between"
      >
        {goBack && (
          <Button
            variant="goBack"
            disableRipple
            startIcon={<ArrowBackOutlined />}
            onClick={goBack}
            sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
          >
            {"Volver atrás"}
          </Button>
        )}
        <Button
          onClick={submitUpdateDocuments}
          disabled={!!entity?.requested || disabledButton}
          sx={{
            height: 40,
            fontSize: 12,
            alignSelf: "self-end",
          }}
        >
          Actualizar Documentos
        </Button>
      </Grid>
      <Alert
        icon={alert.icon}
        open={alert.open}
        handleConfirmation={handleConfirmation}
        title={alert.title}
        messages={alert.messages}
      />
    </Stack>
  );
}
